import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
// import { Typography } from '@material-ui/core';
// import { Typography } from '@material-ui/core';

// export interface AdDialogProps {
//   adDialogCallback: () => void;
// }

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export interface AdDialogProps {
  setCashierDialogAction: (data: any) => void;
}

export default function AdDialog(props: AdDialogProps) {
  const { setCashierDialogAction } = props;

  const [open, setOpen] = React.useState(false);

  const [img, setImg] = React.useState('');

  useEffect(() => {
    const lastPopUpStorage: string | null = localStorage.getItem('lastPopUp1');

    const lastPopUp = lastPopUpStorage ? JSON.parse(lastPopUpStorage) : { day: null, cont: null };

    if (lastPopUp == null || lastPopUp.day == null || lastPopUp.cont == null || lastPopUp.day !== new Date().getDate().toString()) {
      localStorage.setItem('lastPopUp1', JSON.stringify({ day: new Date().getDate().toString(), cont: 1 }));

      setImg(process.env.PUBLIC_URL + '/images/market/bonus.jpg');
    } else {
      switch (lastPopUp.cont) {
        case 0:
          setImg(process.env.PUBLIC_URL + '/images/market/bonus.jpg');
          localStorage.setItem('lastPopUp1', JSON.stringify({ ...lastPopUp, cont: 1 }));
          break;
        // case 1:
        //   setImg(process.env.PUBLIC_URL + '/images/market/bracket-contest.jpg');
        //   localStorage.setItem('lastPopUp1', JSON.stringify({ ...lastPopUp, cont: 0 }));
        //   break;
        case 1:
          setImg(process.env.PUBLIC_URL + '/images/market/350-new-casino-games.png');
          localStorage.setItem('lastPopUp1', JSON.stringify({ ...lastPopUp, cont: 0 }));
          break;
        default:
          return;
      }
    }

    const keepShowingAd = localStorage.getItem('keepShowingAdRaf');

    if (keepShowingAd == 'true' || keepShowingAd == null) {
      setTimeout(() => setOpen(true), 2000);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  //   const dontShowAnymore = () => {
  //     localStorage.setItem('keepShowingAdRaf', 'false');
  //     setOpen(false);
  //   };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        {/* Game Ad */}
        {/* <DialogContent>
          <img
            src={`${process.env.PUBLIC_URL}/images/market/game1018.png`}
            style={{ width: '100%', height: 'auto', maxHeight: '600px' }}
            onClick={() => {
              getHistory().push('/cashier');
              handleClose();
            }}
          />
        </DialogContent> */}

        {/* Maintenance */}
        {/* <DialogTitle id='alert-dialog-slide-title'>{'VIPClub'}</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Typography variant='h5'>
            Live Betting
            <br />
            Scheduled Maintenance
          </Typography>
          <Typography variant='h6'>04/25/2022 from 21:00 hours to 23:00 hours PST.</Typography>
        </DialogContent> */}

        {/* RAF AD */}

        <DialogTitle id='alert-dialog-slide-title'>{'VIPClub'}</DialogTitle>
        <DialogContent>
          <img
            src={img}
            style={{ width: '100%', height: 'auto', maxHeight: '600px', maxWidth: '400px' }}
            onClick={() => {
              setCashierDialogAction({ isShow: true, type: 'cashier' });
              handleClose();
            }}
          />
        </DialogContent>

        {/* Live Betting Down */}
        {/* <DialogTitle id='alert-dialog-slide-title'>Live Betting</DialogTitle>
        <DialogContent>
          <Typography variant='h5'>Attention</Typography>
          <Typography variant='h6'>
            Live betting platform will be down for maintenance today from 23:00PDT to 01:00PDT, we appreciate your understanding in this
            matter.
            <br />
            Thank you.
          </Typography>
        </DialogContent> */}

        {/* MM AD */}
        {/* <DialogTitle id='alert-dialog-slide-title'>{'VIPClub'}</DialogTitle>
        <DialogContent>
          <img
            src={`${process.env.PUBLIC_URL}/images/market/mm.png`}
            style={{ width: '100%', height: 'auto', maxHeight: '600px' }}
            onClick={() => {
              getHistory().push('/bracket');
              handleClose();
            }}
          />
        </DialogContent> 

        {/* Announcement */}
        {/* <DialogTitle id='alert-dialog-slide-title'>{'Valued Client,'}</DialogTitle>
        <DialogContent>
          <Typography style={{ textAlign: 'center' }}>
            Please make note of the Fee schedule for Deposits and Withdrawals. Operationally, we have not charged for Deposits since we
            opened. But the Fees for Credit Card Processing and Trading every Deposit into Bitcoin on behalf of the client is very
            expensive.
            <br />
            <br />
            Therefore, effective immediately we must begin charging a deposit fee for each transaction. Bitcoin Deposits will remain “No
            Fee” or 0%. But the others must be increased to help minimize the cost.
            <br />
            <br /> On a POSITIVE NOTE, we have REDUCED the Instant Pay Withdrawal Fee from 10% to 5%! This allows each Valued Client to
            receive their WITHDRAWALS IN SECONDS and at a reduced fee going forward. Thank you for your business and understanding!
            <br />
            <br />
            Respectfully,
            <br />
            VIP Club Management
          </Typography>
        </DialogContent> */}

        <DialogActions>
          {/* <Button onClick={dontShowAnymore} color='default' variant='outlined'>
            Don&apos;t Show Again
          </Button> */}
          <Button onClick={handleClose} color='secondary' variant='contained'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
