export enum TagComponentsEnum {
  INTERNAL = 18,
  EXTERNAL = 19,
}

export enum ComponentEnum {
  MAIN_PAGE = 52,
  CHANNELS = 53,
  ID_VERIFICATION_BLOCKLIST = 54,
  IP_BLACKLIST = 55,
  CASINO = 56,
  LIVE_DEALER = 57,
  RACEBOOK = 58,
  REFER_A_FRIEND = 59,
  MY_ACCOUNT = 60,
  RULES = 61,
  PLAYER_HISTORY = 62,
  TRANSACTION_HISTORY = 63,
  BONUS_HISTORY = 64,
  AGENT_DASHBOARD = 65,
  PENDING_BETS = 66,
  CASHIER = 67,
  CASHIER_WITHDRAWALS = 68,
  CASHIER_DEPOSITS = 69,
  LOGIN = 70,
  FORGOT_PASSWORD = 71,
  SIGNUP = 72,
}

export enum HeaderButtonViewEnum {
  NONE = 0,
  BRACKET = 1,
  PICK = 2,
}

export interface HeaderButtonInfo {
  name: string;
  image: string;
}

export const GetCurrentHeaderButtonView = (): number => {
  return HeaderButtonViewEnum.NONE;
};

export const GetCurrentHeaderButtonViewInfo = (): HeaderButtonInfo => {
  return {
    image: 'https://api.pdub.lv/src/images/bracket/4/NCAAMarchMadness2.png',
    name: '',
  };
};
