import { useLayoutEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { InvitationCodeEnum } from '../enum/signup/invitation-code-enum';
import moment from 'moment';
const cookies = new Cookies();

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const handleGetInviteCode = () => {
  let invite_code: string = InvitationCodeEnum.DEFAULT;
  try {
    const cookie_invite_code = cookies.get('inviter_code');
    if (cookie_invite_code != null && cookie_invite_code != '') {
      invite_code = cookie_invite_code;
    }
  } catch (error) {
    console.error('Error Getting Invite Code', error);
  }
  return invite_code;
};

export const removeCookies = () => {
  const cookie_invite_code = cookies.get('inviter_code');
  if (cookie_invite_code != null) {
    console.log('FN removeCookies V2');
    const momentDate = moment().add(-1, 'month');
    cookies.set('inviter_code', cookie_invite_code, {
      path: '/',
      domain: location.hostname,
      expires: momentDate.toDate(),
      secure: true,
      sameSite: true,
    });
  }
};
