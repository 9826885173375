/**
 * @export
 *  Providers
 */
export enum SocketMessageEnum {
  MESSAGE = 1,
  SHOWDIALOG = 2,
  SHOWDIALOGANDOPENCHAT = 3,
  SNACKBAR = 3,
}

export enum SocketRooms {
  VIPPUBLIC = 'VIPPUBLIC',
}
