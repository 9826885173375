type MapType = { [key: string]: any };

/**
 * Holds the application settings
 */
let appSettings: MapType;

export function getAppSettings(): MapType {
  return appSettings;
}

/**
 * if default value is 'undefined' raises error if key is missing
 * @param key
 * @param defaultValue
 */
export function getAppSetting(key: string, defaultValue?: any): any {
  if (!appSettings[key]) {
    if (defaultValue === undefined) {
      throw new Error(`Required value for key: ${key} is missing in appSettings.`);
    }
    return defaultValue;
  }
  return appSettings[key];
}

export function setAppSettings(settings: MapType): MapType {
  appSettings = settings;
  return appSettings;
}
