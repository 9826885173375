import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, createStyles, Divider, Grid, Typography } from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { makeStyles } from '@material-ui/styles';
import { IIdentityDialog } from '../../../models/users';
import { Loading } from '../../loading/loading.component';
import { IBonus } from '../../../models/Bonus/bonus';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'table',
      height: '100%',
      position: 'absolute',
      overflow: 'hidden',
      width: '100%',
      backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/login-bg.jpg` + ')',
      backgroundSize: '1920px 1080px',
      backgroundRepeat: 'no-repeat',
      backgroundColor: 'black',
      backgroundPosition: 'top center',
      paddingBottom: '4rem',
    },

    textPadding: {
      paddingTop: '8px',
      paddingBottom: '10px',
    },
    tabPanel: {
      display: 'flex',
      textAlign: 'center',
      padding: '0px',
      justifyContent: 'center',
    },
    hidden: {
      display: 'none!important',
    },
    bottomBtnContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    successGrid: {
      marginTop: '0px',
      textAlign: 'center',
    },
    alert: {
      marginTop: '10px',
    },
    limitations: {
      display: 'inline-flex',
      textAlign: 'left',
    },
    alertTitle: {
      width: '100%',
      fontSize: '1.1rem',
      '& .MuiAlert-message': {
        width: '100%',
      },
      '& .MuiAlert-icon': {
        display: 'none',
      },
    },
    fwTextCenter: {
      width: ' 100%',
      textAlign: 'center',
    },
    fw: {
      width: ' 100%',
    },
    UnLimitedOption: {
      fontSize: '20px',
      color: 'green',
      marginRight: '4px',
      textAlign: 'left',
    },
    LimitedOption: {
      fontSize: '15px',
      color: 'orange',
      marginRight: '4px',
    },
    buttonContinue: {
      backgroundColor: '#c8a964 !important',
      color: 'white !important',
      '&:hover': {
        color: 'white !important',
      },
    },
    noContinueButton: {
      color: 'orange !important',
      border: '1px solid #ffa50045',
    },
    divCenter: {
      marginBottom: '1rem',
      borderRadius: '5px',
      padding: '.5rem',
      width: '90%',
      margin: 'auto',
      textAlign: 'center',
    },
    orangeBox: {
      marginBottom: '1rem',
      borderRadius: '5px',
      padding: '.5rem',
      width: '90%',
      margin: 'auto',
      textAlign: 'center',
      border: '1px solid orange',
    },
    greenBox: {
      marginBottom: '1rem',
      border: '1px solid green',
      borderRadius: '5px',
      padding: '.5rem',
    },
    textPoints: {
      fontSize: '0.75rem !important',
    },
    successText: {
      textAlign: 'center',
      width: '100%',
      marginBottom: '1rem',
    },
    loginButton: {
      marginBottom: '1.5rem',
      paddingLeft: 0,
    },
    alreadyOnBonusCard: {
      textAlign: 'center',
      padding: 15,
      marginBottom: 10,
    },
    welcomeBonusGif: {
      width: '100%',
    },
  }),
);

export interface ExternalProps {
  identityDialogOpen: boolean;
  shareable_url: string | null;
  cognitoMatch: number;
  message: string | null;
  isLoading: boolean;
  success: boolean;
  playerProfileSettingsPaymentMethodBonuses: IBonus[];
}

export interface ExternalActionProps {
  toggleIdentityDialog: (state: IIdentityDialog) => void;
  createCognitoRetryAction: () => void;
}
export default function IdentityDialog(props: ExternalProps & ExternalActionProps) {
  const {
    identityDialogOpen,
    message,
    isLoading,
    toggleIdentityDialog,
    cognitoMatch,
    playerProfileSettingsPaymentMethodBonuses,
    success,
    shareable_url,
  } = props;

  const classes = useStyles();
  const handleClose = () => {
    toggleIdentityDialog({
      open: false,
      message: null,
      result: false,
      playerProfileSettingsPaymentMethodBonuses: playerProfileSettingsPaymentMethodBonuses,
    });
  };

  useEffect(() => {
    if (cognitoMatch !== undefined && cognitoMatch !== 1) {
      toggleIdentityDialog({
        message: message,
        open: true,
        result: false,
        playerProfileSettingsPaymentMethodBonuses: playerProfileSettingsPaymentMethodBonuses,
      });
    }
  }, [cognitoMatch]);

  return (
    <>
      <Loading loading={isLoading} />
      <Dialog open={identityDialogOpen} TransitionComponent={Transition} keepMounted onClose={handleClose}>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ textAlign: 'center', marginTop: '15px !important' }}>
              <Alert
                severity={`${success == true ? 'success' : 'warning'}`}
                variant={'filled'}
                style={{ marginBottom: '1rem' }}
                className={classes.alertTitle}
              >
                {message && message.trim().length > 0 ? message : 'Identity Verification'}
              </Alert>

              {success == false ? (
                <>
                  <Grid container className={classes.greenBox} spacing={1}>
                    <Grid item xs={12} className={classes.limitations}>
                      <Typography className={classes.fwTextCenter} variant={'body1'}>
                        Would you like to have access to the ENTIRE VIP Experience?
                      </Typography>
                    </Grid>

                    <Divider color={'green'}></Divider>

                    <Grid item xs={6} className={classes.limitations}>
                      <CheckCircleOutlineIcon className={classes.UnLimitedOption} />
                      <Typography variant={'body2'}>Wide Variety of Deposit Methods</Typography>
                    </Grid>

                    <Grid item xs={6} className={classes.limitations}>
                      <CheckCircleOutlineIcon className={classes.UnLimitedOption} />
                      <Typography variant={'body2'}>Instant Withdrawals</Typography>
                    </Grid>

                    <Grid item xs={6} className={classes.limitations}>
                      <CheckCircleOutlineIcon className={classes.UnLimitedOption} />
                      <Typography variant={'body2'}>Higher Wager Limits</Typography>
                    </Grid>

                    <Grid item xs={6} className={classes.limitations}>
                      <CheckCircleOutlineIcon className={classes.UnLimitedOption} />
                      <Typography variant={'body2'}>Best Bonuses and Low Rollovers</Typography>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12}>
                      <Button
                        variant='contained'
                        type='button'
                        form='signupForm'
                        color='primary'
                        className={classes.buttonContinue}
                        style={{ marginTop: '1rem', fontSize: '0.75rem' }}
                        fullWidth
                        disabled={shareable_url == null ? true : false}
                        onClick={() => {
                          if (shareable_url) window.open(shareable_url);
                        }}
                      >
                        Yes, continue to verify my identity
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant='outlined'
                        type='button'
                        form='signupForm'
                        color='primary'
                        fullWidth
                        disabled={isLoading}
                        className={classes.noContinueButton}
                        style={{ marginTop: '1rem', marginBottom: '1rem', fontSize: '0.75rem' }}
                        onClick={handleClose}
                      >
                        <span> No, continue without verifying my identity</span>
                      </Button>
                    </Grid>
                  </Grid>

                  <div className={classes.divCenter}>
                    <Typography className={classes.fwTextCenter} variant={'subtitle2'}>
                      Failure to verify identity will only allow your account to the following options
                    </Typography>
                  </div>

                  <Grid container className={classes.orangeBox} spacing={1}>
                    <Grid item xs={12} className={classes.limitations}>
                      <Typography className={classes.fwTextCenter} variant={'body2'}>
                        Limited Access
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.limitations}>
                      <ErrorOutlineOutlinedIcon className={classes.LimitedOption} />
                      <Typography className={classes.textPoints} variant={'subtitle2'}>
                        Bitcoin Deposit Only
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.limitations}>
                      <ErrorOutlineOutlinedIcon className={classes.LimitedOption} />
                      <Typography className={classes.textPoints} variant={'subtitle2'}>
                        Bitcoin Withdrawals Only
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.limitations}>
                      <ErrorOutlineOutlinedIcon className={classes.LimitedOption} />
                      <Typography className={classes.textPoints} variant={'subtitle2'}>
                        $250 Wager Limit
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <div className={classes.divCenter}>
                    <br />

                    <Typography className={classes.fwTextCenter} variant={'subtitle2'}>
                      Your account is fully verified and you can now enjoy the Entire Experience.
                    </Typography>

                    <br />

                    {playerProfileSettingsPaymentMethodBonuses.length > 0 && (
                      <>
                        <Alert severity='success' classes={{ root: classes.alreadyOnBonusCard }} icon={false}>
                          <Typography variant='body2'>
                            To claim your NON DEPOSIT WELCOME BONUS, please click on the CASHIER section and select deposit. Follow the
                            on-screen instructions to access your NON DEPOSIT WELCOME BONUS.
                          </Typography>
                        </Alert>

                        <br />

                        <img
                          className={classes.welcomeBonusGif}
                          alt='welcome-bonus-gif'
                          src={`${process.env.PUBLIC_URL}/gifs/welcome-bonus.gif`}
                        />
                      </>
                    )}

                    <br />

                    <Button
                      variant='outlined'
                      type='button'
                      color='secondary'
                      fullWidth
                      className={classes.buttonContinue}
                      style={{ marginTop: '1rem', marginBottom: '1rem', fontSize: '0.75rem', width: '50%' }}
                      onClick={handleClose}
                    >
                      <span>Close</span>
                    </Button>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
