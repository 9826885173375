import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CasinoGameDto } from '../../../models/CasinoLobbyV2/CasinoLobbyDto';
import { VipAutoSpinIcon } from '../../../shared/icons/auto-spin-icon';
import { VipCasinoMachineIcon } from '../../../shared/icons/casino-machine-icon';
import { VipExchangeIcon } from '../../../shared/icons/exchange-icon';
import { VipFreeSpinIcon } from '../../../shared/icons/free-spin-icon';
import { VipHandTap } from '../../../shared/icons/hand-tap';
import { VipHeartMinusIcon } from '../../../shared/icons/heart-minus-icon';
import { VipHeartPlusIcon } from '../../../shared/icons/heart-plus-icon';
import { VipPennantIcon } from '../../../shared/icons/pennant-icon';
import { VipPlayIcon } from '../../../shared/icons/play-circle-icon';
// import { getAppSettings } from '../../../utils';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      float: 'left',
      width: '100%',
      height: '180px',
      background: '#202020',
      borderBottom: 'solid 1px #000',
      borderRadius: '5px',
    },
    containerViewAll: {
      width: 'calc(100% - 10px)',
      marginBottom: '10px',
      position: 'relative',
      float: 'left',
      height: '180px',
      background: '#202020',
      borderBottom: 'solid 1px #000',
      borderRadius: '5px',
    },
    containerPlay: {
      height: '120px',
      width: '100%',
      margin: 'auto',
      zIndex: 40,
      position: 'absolute',
      backgroundColor: '#24221cd6',
      borderTopRightRadius: '5px',
      borderTopLeftRadius: '5px',
      display: 'none',
      '&:hover': {
        display: 'table',
        marginTop: '-180px',
      },
    },
    gameTitle: {
      float: 'left',
      padding: '20px 0',
      fontSize: '0.75rem',
      width: '100%',
      color: '#c8a964',
      top: '-50px',
      position: 'relative',
      marginTop: '20px',
    },
    content: {
      float: 'left',
      padding: '5% 10%',
      fontSize: '16px',
      bottom: '0',
      width: '80%',
      color: '#fff',
      textAlign: 'justify',
    },
    gameImage: {
      height: '100%',
      width: '100%;',
      display: 'block',
      borderRadius: '5px',
      '&:hover + $containerPlay': {
        display: 'table',
        marginTop: '-180px',
      },
    },
    tags: {
      fontSize: '0.75em',
      marginBottom: '5px',
      marginTop: '5px',
      width: '100%',
    },
    gameImageProvider: {
      width: '60px',
      marginTop: '-9px',
      filter: 'grayscale(1)',
      height: '20px',
      pointerEvents: 'none',
    },
    gameDescription: {
      marginTop: '-75px',
    },
    gameContentTags: {
      width: '90%',
      margin: 'auto',
    },
    playIcon: {
      display: 'table-cell',
      verticalAlign: 'middle',
      textAlign: 'center',
      color: '#c8a964',
      cursor: 'pointer',
      position: 'relative',
    },
    gameInfo: {
      position: 'absolute',
      zIndex: 100,
      bottom: '0',
      width: '100%',
      height: '60px',
      cursor: 'pointer',
      overflow: 'hidden',
      textAlign: 'center',
      transition: 'all .4s ease-in-out',
      backgroundColor: '#2d2d2dd9',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',

      '&:hover, :active, :focus': {
        marginTop: '10%',
        height: '100%',
        borderRadius: '5px',
        '& $gameTitle': {
          paddingTop: '25px',
        },
      },
    },
    containerPlayViewAllProvider: {
      position: 'absolute',
      bottom: '-13px',
      // marginLeft: '30px',
      left: '0px',
      right: '0',
      textAlign: 'center',
      [theme.breakpoints.down(500)]: {
        fontSize: '14px',
      },
    },
    mainContainerViewMore: {
      border: '2px #c8a964 solid',
      borderRadius: '5px',
    },
    viewMoreIcon: {
      width: '40% !important',
      height: '25% !important',
      marginRight: '30%',
      marginLeft: '30%',
      marginTop: '10%',
      color: '#c8a964',
    },
    viewMoreContainer: {
      height: '180px',
      width: '100%',
      margin: 'auto',
      display: 'inline-block',
    },
    viewMoreGameTitle: {
      textAlign: 'center',
      [theme.breakpoints.down(900)]: {
        fontSize: '11px',
      },
    },
    banner: {
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: ' 0 200px 200px 0',
      borderColor: 'transparent #057eff transparent transparent',
    },
    spanIcon: {
      width: '100%',
      textAlign: 'center',
      // [theme.breakpoints.up('md')]: {
      //   float: 'left',
      // },
      // [theme.breakpoints.down('md')]: {
      //   fontSize: '0.75rem',
      // },
    },
  }),
);

export interface ExternalProps {
  game?: CasinoGameDto;
  viewAll: boolean;
  viewMore: boolean;
  isMobile: boolean;
  imageCDNUrl: string;
  setGame: (data: any) => void;
  setViewAll: (data: any) => void;
  addUpdateFavoriteGamePlayerAction: (data: any) => void;
}

export const GameCardComponent = (prop: ExternalProps) => {
  const classes = useStyles();
  const { game, viewMore, setViewAll, viewAll, setGame, isMobile, imageCDNUrl, addUpdateFavoriteGamePlayerAction } = prop;
  const [gameHeight, setGameHeight] = useState(150);
  // let url = getAppSettings().apiUrl;
  // url = url.slice(0, -4);
  imageCDNUrl ?? '';
  const HandleClickSetGame = () => {
    setGame(game);
  };

  const HandleClickSetGameImage = () => {
    if (isMobile) setGame(game);
  };

  const HandlerClick = () => {
    //Aqui es redireccionar a la categorias completa.
    setViewAll(true);
  };

  const handlerSaveFavorite = () => {
    if (game && game.game_id)
      addUpdateFavoriteGamePlayerAction({
        game_id: game.game_id,
        is_favorite: false,
      });
  };

  const iconStyle = {
    style1: {
      filter: 'invert(64%) sepia(74%) saturate(256%) hue-rotate(3deg) brightness(94%) contrast(84%)',
      width: '25px',
      height: '25px',
      marginRight: '5px',
      marginLeft: '5px',
      marginTop: '-10px',
    },
    liston: {
      filter: 'invert(73%) sepia(59%) saturate(325%) hue-rotate(2deg) brightness(86%) contrast(83%)',
      width: '32%',
      height: '16%',
      position: 'absolute',
      top: '-2%',
      zIndex: '20',
    },
    liston2: {
      filter: 'invert(73%) sepia(59%) saturate(325%) hue-rotate(2deg) brightness(86%) contrast(83%)',
      width: '32%',
      height: '16%',
      position: 'absolute',
      top: '1%',
      zIndex: '20',
      left: '33px',
    },
    liston3: {
      filter: 'invert(73%) sepia(59%) saturate(325%) hue-rotate(2deg) brightness(86%) contrast(83%)',
      width: '32%',
      height: '16%',
      position: 'absolute',
      top: '2%',
      zIndex: '20',
      left: '66px',
    },
    icon: {
      filter: 'invert(92%) sepia(80%) saturate(5597%) hue-rotate(184deg) brightness(129%) contrast(109%)',
      width: '21%',
      height: '7%',
      position: 'absolute',
      left: '5%',
      top: '2%',
      zIndex: '30',
    },
    icon2: {
      filter: 'invert(92%) sepia(80%) saturate(5597%) hue-rotate(184deg) brightness(129%) contrast(109%)',
      width: '21%',
      height: '7%',
      position: 'absolute',
      left: '46px',
      top: '1%',
      zIndex: '30',
    },
    icon3: {
      filter: 'invert(14%) sepia(94%) saturate(4322%) hue-rotate(356deg) brightness(79%) contrast(90%)',
      width: '21%',
      height: '7%',
      position: 'absolute',
      right: '0',
      top: '2%',
      zIndex: 100,
    },
  };

  const icons = {
    width: '100%',
    height: '25px',
    margin: 'auto',
  };

  const setViewPort = () => {
    let _slidesPerView = 3;
    if (window.innerWidth <= 400) _slidesPerView = 3;
    else if (window.innerWidth > 400 && window.innerWidth <= 480) _slidesPerView = 4;
    else if (window.innerWidth > 480 && window.innerWidth <= 720) _slidesPerView = 5;
    else if (window.innerWidth > 720 && window.innerWidth <= 1024) _slidesPerView = 6;
    else if (window.innerWidth > 1024 && window.innerWidth <= 1280) _slidesPerView = 5;
    else if (window.innerWidth > 1280 && window.innerWidth <= 1600) _slidesPerView = 6;
    else _slidesPerView = 8;
    return _slidesPerView;
  };

  const handleGameHeight = () => {
    let _gameHeight = 150;
    const _slidesPerView = setViewPort();
    const _gameCarrouselContWidth = document.getElementsByClassName('gameCarrouselCont')[0]?.clientWidth;
    const allMargin = (_slidesPerView - 1) * 10;
    _gameHeight = (((_gameCarrouselContWidth - allMargin) / _slidesPerView) * 4) / 3;
    setGameHeight(_gameHeight);
  };

  window.addEventListener('resize', () => {
    handleGameHeight();
  });

  useEffect(() => {
    handleGameHeight();
  }, []);

  return (
    <>
      {!viewMore && game && (
        <>
          <div style={{ height: gameHeight }} className={`${!viewAll ? classes.container : classes.containerViewAll} `}>
            {game && game.favorite_games_by_player_is_favorite != null && game.favorite_games_by_player_is_favorite == true && (
              <>
                <VipPennantIcon style={iconStyle.liston} />
                <VipHeartPlusIcon style={iconStyle.icon} />
                <span onClick={handlerSaveFavorite}>
                  <VipHeartMinusIcon style={iconStyle.icon3} />
                </span>
              </>
            )}
            {/* New CDN */}
            <img src={`${imageCDNUrl + game.game_image}`} className={classes.gameImage} onClick={HandleClickSetGameImage}></img>
            {/* <img src={`${'https://api.pdub.lv' + game.game_image}`} className={classes.gameImage} onClick={HandleClickSetGameImage}></img> */}

            <div
              className={classes.containerPlay}
              onClick={HandleClickSetGame}
              style={{ height: gameHeight - 60, marginTop: gameHeight * -1 }}
            >
              <span className={classes.playIcon}>
                <VipPlayIcon style={{ width: '25%', height: '25%', padding: '0px !important' }} />
                {/* Demo. Remover despues */}
                <VipHandTap
                  className={'vip-fadeOut-4'}
                  style={{ width: '50%', height: '50%', position: 'absolute', left: 0, right: 0, margin: '3% auto' }}
                />
              </span>
              <h3 className={classes.containerPlayViewAllProvider}>{game.provider_description}</h3>
            </div>

            <div className={classes.gameInfo}>
              <div className={classes.gameTitle}>
                <p>{game.game_description.substring(0, 20)}</p>
                {/* New CDN here */}
                <img className={classes.gameImageProvider} src={`${imageCDNUrl}${game.provider_image}`}></img>
                {/* <img
                  className={classes.gameImageProvider}
                  src={`https://api.pdub.lv/images/providers/${game.provider_description.toLocaleLowerCase()}/logo.png`}
                ></img> */}
              </div>
              <div className={classes.gameContentTags}>
                <Grid container className={classes.gameTitle} style={{ top: '-125px' }}>
                  <span className={classes.tags}>Tags:</span>
                  {game.tags &&
                    Array.isArray(game.tags) &&
                    game.tags.length > 0 &&
                    game.tags.map((tag: string, index: number) => {
                      return (
                        <>
                          {index < 6 && (
                            <Grid item xs={6} key={`TagKey${index}`} className={`${classes.tags} vip-fadeIn`}>
                              <Chip label={tag} size='small' />
                            </Grid>
                          )}
                        </>
                      );
                    })}
                </Grid>

                <Grid container style={{ position: 'relative', top: '-135px' }}>
                  {game.has_gamble == true && (
                    <Grid item xs={6} style={{ marginTop: '2px', marginBottom: '2px' }}>
                      <VipExchangeIcon style={icons} />
                      <span className={classes.spanIcon}> Gamble </span>
                    </Grid>
                  )}

                  {game.has_jackpot == true && (
                    <Grid item xs={6} style={{ marginTop: '2px', marginBottom: '2px' }}>
                      <VipCasinoMachineIcon style={icons} /> <span className={classes.spanIcon}> Jackpot</span>
                    </Grid>
                  )}

                  {game.has_free_rounds == true && (
                    <Grid item xs={6} style={{ marginTop: '2px', marginBottom: '2px' }}>
                      <VipFreeSpinIcon style={icons} />
                      <span className={classes.spanIcon}> Freespin</span>
                    </Grid>
                  )}
                  {game.has_auto_spin == true && (
                    <Grid item xs={6} style={{ marginTop: '2px', marginBottom: '2px' }}>
                      <VipAutoSpinIcon style={icons} />
                      <span className={classes.spanIcon}> Autospin</span>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          </div>
        </>
      )}

      {viewMore && (
        <div className={classes.mainContainerViewMore} style={{ height: gameHeight - 4 }}>
          <div className={classes.viewMoreContainer} onClick={HandlerClick}>
            <strong className={classes.viewMoreGameTitle}>
              <h2>View More</h2>
            </strong>
            <FontAwesomeIcon className={classes.viewMoreIcon} icon={['fad', 'plus-circle']} />
          </div>
        </div>
      )}
    </>
  );
};
