import globToRe from 'glob-to-regexp';
import { getAppSettings } from '../../../utils/app-settings.util';
import { axiosResult } from '../../../utils/axios/axios-result';
import landingPages from './landing-pages.json';

export function mockApi(axiosMock: any): any {
  const getUrl = `${getAppSettings().apiUrl}/LandingPage/GetAllPages`;
  axiosMock.onPost(globToRe(getUrl)).reply(() => axiosResult(landingPages));

  const createUrl = `${getAppSettings().apiUrl}/LandingPage/Create`;
  axiosMock.onPost(globToRe(createUrl)).reply(() => axiosResult(landingPages));

  const updateUrl = `${getAppSettings().apiUrl}/LandingPage/Update`;
  axiosMock.onPost(globToRe(updateUrl)).reply(() => axiosResult(landingPages));

  const deleteUrl = `${getAppSettings().apiUrl}/LandingPage/Delete`;
  axiosMock.onPost(globToRe(deleteUrl)).reply(() => axiosResult(landingPages));

  const setActiveUrl = `${getAppSettings().apiUrl}/LandingPage/SetActive`;
  axiosMock.onPost(globToRe(setActiveUrl)).reply(() => axiosResult(landingPages));
}
